import React, { useState } from "react";
import logo from "./logo.svg";
import type { MenuProps } from "antd";
import { Divider, Form, Grid, Layout, Menu, Segmented, theme, Typography } from "antd";


import type { InputNumberProps } from "antd";
import { Col, InputNumber, Row, Slider, Space } from "antd";
import FormSlider from "./FormSlider";
import { ArrowDownOutlined, HeartOutlined } from '@ant-design/icons';
import { Card, Statistic } from 'antd';
import PulseZones from "./PulseZones";
const { Title,Text } = Typography;
interface CalculatorState {
    age: number;
    restingHeartRate: number;
    weight: number;
    gender: 'male'|'female'
    }

function Calculator() {
  const state: CalculatorState = {
    age: 30,
    restingHeartRate: 60,
    weight: 70,
    gender:"female"
  };

    const [age, setAge] = useState(state.age);
    const [restingHeartRate, setRestingHeartRate] = useState(state.restingHeartRate);
    const [weight, setWeight] = useState(state.weight);
    const [gender, setGender] = useState(state.gender);

    const onAgeChange = (value: number) => {
      setAge(value);
    };

    const onPulseChange = (value: number) => {
      setRestingHeartRate(value);
    };

    const onWeightChange = (value: number) => {
      setWeight(value);
    };

    const onGenderChange = (value: string) => {
        if(value == 'male'){
          setGender('male');
        } else {
          setGender('female');
        }
    };

    
     
  function getEdwardsMaxPulse(age: number, restingHeartRate: number, gender: string, weight: number): number {
    // Männer: Maximalpuls = 214 - 0,5 × Lebensalter - 0,11 × Körpergewicht in kg
    // Frauen: Maximalpuls = 210 - 0,5 × Lebensalter - 0,11 × Körpergewicht in kg 
    let bpm = 210 - 0.5 * age - 0.11 * weight;
    if(gender == 'male'){
      bpm = 214 - 0.5 * age - 0.11 * weight;
    }
    return Math.round(bpm);
  }

  function getSpanausMaxPulse(age: number, gender: string): number {
    //Männer: Maximalpuls = 223 - 0,9 × Lebensalter
    //Frauen: Maximalpuls = 226 - 0,9 × Lebensalter
    let bpm = 226 - 0.9 * age;
    if(gender == 'male'){
      bpm = 223 - 0.9 * age;
    }
    return Math.round(bpm);
  }

  function getOptimalTrainingPulse(age: number, restingHeartRate: number, gender: string, weight: number, faktor: number): number {
    // Trainingsherzfrequenz = (HFmax - Ruhepuls) × Faktor + Ruhepuls
    // Faktor: 60-80%
    let maxPulse: number = getEdwardsMaxPulse(age, restingHeartRate, gender, weight);
    let bpm: number = ((maxPulse - restingHeartRate) * faktor) + restingHeartRate;
    return Math.round(bpm);
  }

  return (
    <>
    
    <FormSlider value={age} label="Alter" onChange={onAgeChange} min={1} max={99}></FormSlider>
    <FormSlider value={restingHeartRate} label="Ruhepuls" onChange={onPulseChange} min={30} max={120}></FormSlider>
    <FormSlider value={weight} label="Gewicht" onChange={onWeightChange} min={20} max={200}></FormSlider>
    <Row>
        <Col span={4} style={{textAlign:"center"}}><Text style={{textAlign:"center"}}>Geschlecht:</Text></Col>
        <Col span={20}><Segmented value={gender} options={[
            {label: 'männlich', value: 'male'},
            {label: 'weiblich', value: 'female'}
        ]} onChange={onGenderChange} block /></Col>
    </Row>
    <Divider orientation="left"/>

    <Row gutter={16}>
    <Col span={12}>
      <Card bordered={false}>
        <Statistic
          title="Maximalpuls (nach Edwards)"
          value={getEdwardsMaxPulse(age, restingHeartRate, gender, weight)}
          precision={0}
          valueStyle={{ color: '#3f8600' }}
          prefix={<HeartOutlined />}
          suffix="Schläge pro Minute"
        />
      </Card>
    </Col>
    <Col span={12}>
      <Card bordered={false}>
        <Statistic
          title="Maximalpuls für trainierte Sportler (nach Spanaus)"
          value={getSpanausMaxPulse(age, gender)}
          precision={0}
          valueStyle={{ color: '#3f8600' }}
          prefix={<HeartOutlined />}
          suffix="Schläge pro Minute"
        />
      </Card>
    </Col>
    </Row>
    <Divider orientation="left"/>
    <Row gutter={16} >
    <Col span={24}>
      <Card bordered={false}>
        <Statistic
          title="Optimaler Trainingspuls"
          value={getOptimalTrainingPulse(age, restingHeartRate,gender, weight, 0.5) +' - '+ getOptimalTrainingPulse(age, restingHeartRate,gender, weight, 0.8)}
          precision={0}
          valueStyle={{ color: '#3f8600' }}
          prefix={<HeartOutlined />}
          suffix="Schläge pro Minute"
        />
      </Card>
    </Col>
  </Row>
  <Divider orientation="left"/>

  <Row>
    <Col span={24}>
      <PulseZones rate={getEdwardsMaxPulse(age,restingHeartRate,gender,weight)}/>
    </Col>
  </Row>
    </>
  );
}

export default Calculator;
