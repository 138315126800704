import React from 'react';
import logo from './logo.svg';
import './App.css';
import type { MenuProps } from 'antd';
import { Col, ConfigProvider,Layout, Menu, Row, theme, Typography } from 'antd';
import Calculator from './components/Calculator';

const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;

function App() {
  const headerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    height: 64,
    paddingInline: 48,
    lineHeight: '64px',
    backgroundColor: '#4096ff',
  };
  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',

  };
  return (
    <ConfigProvider theme={{algorithm: theme.defaultAlgorithm}}>
    <Layout style={layoutStyle} >
    <Header style={headerStyle} >
      <Row justify={'center'}>
          <Title level={2}>Pulsrechner</Title>
      </Row>

    </Header>
    <Layout>
      <Content>
        <Calculator></Calculator>
      </Content>
    </Layout>

  </Layout>
  </ConfigProvider>
    
    );
  
}

export default App;
