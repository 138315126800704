import React, { useState } from "react";
import logo from "./logo.svg";
import type { MenuProps } from "antd";
import { Layout, Menu, theme, Typography } from "antd";

import type { InputNumberProps } from "antd";
import { Col, InputNumber, Row, Slider, Space } from "antd";

const { Title, Text } = Typography;

function FormSlider(props: { label: string, onChange: (value: number) => void, min: number, max: number, value: number}) {
  const [inputValue, setInputValue] = useState(props.value);

  const onChange: InputNumberProps["onChange"] = (newValue) => {
    setInputValue(newValue as number);
    console.log(newValue);
    props.onChange(newValue as number);
  };
  return (
    <Row align={"middle"}>
      <Col span={4} style={{textAlign:"center"}}><Text style={{textAlign:"center"}}>{props.label}:</Text></Col>
      <Col span={16}>
        <Slider
          min={props.min}
          max={props.max}
          onChange={onChange}
          value={typeof inputValue === "number" ? inputValue : 0}
        />
      </Col>
      <Col span={4}>
        <InputNumber
          min={props.min}
          max={props.max}
          type="number"
          value={inputValue}
          onChange={onChange}
        />
      </Col>
    </Row>
  );
}

export default FormSlider;
