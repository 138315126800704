import { Table } from 'antd';
import React from 'react';

const columns = [
  {
    title: 'DES MAXIMALPULSES',
    dataIndex: 'maximalPulses',
    key: 'maximalPulses',
  },
  {
    title: 'BELASTUNGSZONE',
    dataIndex: 'belastungszone',
    key: 'belastungszone',
  },
 // {
   // title: 'BESCHREIBUNG',
 //   dataIndex: 'beschreibung',
 //   key: 'beschreibung',
//  },
];



const PulseZones = (props:{rate:number}) => {
    const data = [
        {
          key: '1',
          maximalPulses: Math.round(props.rate * 0.5) + ' - ' + Math.round(props.rate * 0.6),
          minPulses: 0.5,
          maxPulses: 0.6,
          belastungszone: 'Gesundheitszone',
          trainingsbereich: 'REKOM (Regeneration & Kompensation)',
          beschreibung: 'Stärkung des Herz-Kreislauf-Systems. Ideal für Anfänger.',
        },
        {
          key: '2',
          maximalPulses: Math.round(props.rate * 0.6) + ' - ' + Math.round(props.rate * 0.7),
          minPulses: 0.6,
            maxPulses: 0.7,
          belastungszone: 'Fettstoffwechselzone',
          trainingsbereich: 'GA 1 (Grundlagenausdauer-Training 1)',
          beschreibung: 'Maximale Verbrennung von Kalorien aus Fett. Stärkung des Herz-Kreislauf-Systems und Verbesserung der Fitness.',
        },
        {
          key: '3',
          maximalPulses: Math.round(props.rate * 0.7) + ' - ' + Math.round(props.rate * 0.8),
            minPulses: 0.7,
                maxPulses: 0.8,
          belastungszone: 'Aerobe Zone',
          trainingsbereich: 'GA 1/2 (Grundlagenausdauer-Training 1 bis 2)',
          beschreibung: 'Verbesserung von Atmung und Kreislauf. Ideal zur Steigerung der Ausdauer.',
        },
        {
          key: '4',
          maximalPulses: Math.round(props.rate * 0.8) + ' - ' + Math.round(props.rate * 0.9),
            minPulses: 0.8,
                maxPulses: 0.9,
          belastungszone: 'Anaerobe Zone',
          trainingsbereich: 'GA 2 (Grundlagenausdauer-Training 2)',
          beschreibung: 'Körper kann Sauerstoffbedarf nicht mehr decken. Leistungssportler trainieren kurzfristig in diesem Bereich für max. Leistungszuwachs.',
        },
        {
          key: '5',
          maximalPulses: Math.round(props.rate * 0.9) + ' - ' + Math.round(props.rate * 1),
          minPulses: 0.9,
          maxPulses: 1,
          belastungszone: 'Wettkampfspezifische Ausdauerzone',
          trainingsbereich: 'WSA (Wettkampfspezifisches Ausdauer-Training)',
          beschreibung: 'Annäherung an den Maximalpuls. Gefahr für das Herz bei Freizeitsportlern!',
        },
      ];
  return <Table columns={columns} dataSource={data} />;
};

export default PulseZones;